export {default as Wallet} from "assets/icons/wallet-icon.png"
export {default as Docker} from "assets/icons/docker-icon.png"
export {default as Favorite} from "assets/icons/fav-icon.png"
export {default as Metamask} from "assets/icons/metamask-icon.png"
export {default as Grafana} from "assets/icons/grafana-icon.png"
export {default as Payment} from "assets/icons/payment-icon.png"
export {default as Aptos} from "assets/icons/aptos-icon.png"
export {default as Lava} from "assets/icons/lava-icon.svg"
export {default as Casper} from "assets/icons/casper-icon.png"
export {default as Forta} from "assets/icons/forta-icon.png"
export {default as Horp} from "assets/icons/horp-icon.png"
export {default as Solana}  from "assets/icons/solana-icon.png"
export {default as Aleo}  from "assets/icons/aleo-icon.png"
export {default as Taiko}  from "assets/icons/taiko-icon.png"
export {default as Subspace}  from "assets/icons/subspace-icon.jpeg"
export {default as Chainflip}  from "assets/icons/chainflip-icon.png"
export {default as Namada}  from "assets/icons/namada-icon.jpg"
export {default as Avalanche}  from "assets/icons/avalanche-icon.png"
export { default as MediumIcon } from "./medium-icon.png"
export { default as TelegramIcon } from "./tg-icon.png"
export { default as TwitterIcon } from "./twitter-icon.png"
export { default as YouTubeIcon } from "./yt-icon.png"
export { default as RedditIcon } from "./reddit-icon.png"
export { default as LinkedinIcon } from "./linkedin-icon.png"
export { default as EmailIcon } from "./email-icon.png"


