import { LogoIcon } from "assets/images";
import { Button } from "components";
import "./index.scss";

export default function Header({refCode}) {
  return (
    <header className="header">
      <LogoIcon className="header__image" />
      <Button
        color={"grey"}
        size={"small"}
        content={"Launch App"}
        link={process.env.REACT_APP_LINK_TO_APP + (refCode ? `?ref=${refCode}` : '')} 
      />
    </header>
  );
}
