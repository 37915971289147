import "./index.scss";
import {
	MediumIcon,
	TelegramIcon,
	TwitterIcon,
	YouTubeIcon,
	RedditIcon,
	LinkedinIcon,
	EmailIcon
} from "assets/icons";

export default function Footer() {
	return (
		<footer className="footer">
		<div className="footer__social-links">
			<a
			href={"https://medium.com/@nodefarms"}
			target="_blank"
			>
			<img src={MediumIcon} alt="Medium" draggable={false}/>
			</a>
			<a
			href={"https://t.me/node_farm"}
			target="_blank"
			>
			<img src={TelegramIcon} alt="Telegram" draggable={false}/>
			</a>
			<a
			href={"https://twitter.com/node_farms"}
			target="_blank"
			>
			<img src={TwitterIcon} alt="Twitter" draggable={false}/>
			</a>
			<a
			href={"https://www.youtube.com/@nodefarm"}
			target="_blank"
			>
			<img src={YouTubeIcon} alt="YouTube" draggable={false}/>
			</a>
			<a
			href={"mailto:support@nodefarm.io"}
			target="_blank"
			>
			<img src={EmailIcon} alt="support@nodefarm.io" draggable={false}/>
			</a>
			{/* 
			<img src={RedditIcon} alt="Reddit" className="" />
			<img src={LinkedinIcon} alt="Linkedin" className="" />
			*/}
		</div>
		<p className="footer__text">© 2023 Copyright Node Farm Company.</p>
		</footer>
	);
}
