import {
	Spiral,
	BtcBubble,
	EthBubble,
	Ellipse,
	Diamond,
	LinEllipse,
	Torus,
	Sphere,
	DiamondEllipse,
	SliderRings,
	BenefitsSpiral,
	SphereEllDesktop,
	SphereEllTablet,
	SphereEllMobile
} from "assets/images"
import {
	Wallet,
	Docker,
	Favorite,
	Metamask,
	Grafana,
	Payment,
	Aptos,
	Lava,
	Aleo,	
	Taiko,
	Subspace,
	Chainflip,
	Namada,
	Avalanche,
	Forta,
	Solana,
} from "assets/icons"
import {
	Header,
	Card,
	Benefits,
	Slide,
	SectionLayout,
	Button,
	Footer,
	MainBanner,
	Slider
} from "components"
import { Fade } from "react-reveal"
import "./index.scss"
import useQuery from "hooks"


export default function Main() {
	const query = useQuery();
	
	return(
		<div className="window">
				<div className="hero">
					<div className="decoration ellipse1">
						<Ellipse />
					</div>
					<div className="decoration ellipse2">
						<Ellipse />
					</div>
					<div className="decoration ellipse3">
						<Ellipse />
					</div>
					<Fade top>
						<img src={Spiral} alt="" className="decoration spiral" />
					</Fade>
					<Fade bottom>
						<div className="decoration btc1">
							<BtcBubble />
						</div>
						<div className="decoration btc3">
							<BtcBubble />
						</div>
						<div className="decoration eth1">
							<EthBubble />
						</div>
						<img src={Diamond} alt="" className="decoration diamond" />
						<img src={DiamondEllipse} alt="" className="decoration diamond-ellipse" />
						<div className="lin-ellipse-box">
							<div className="decoration btc2">
								<BtcBubble />
							</div>
							<img src={LinEllipse} alt="" className="decoration lin-ellipse" />
						</div>
					</Fade>
					<div className="wraper container">
						<Fade bottom>
							<Header refCode={query.get("ref")}/>
							<MainBanner refCode={query.get("ref")}/>
						</Fade>
					</div>
				</div>
			<div className="background">
				<section className="beginners">
				<div className="flash" />
				<Fade bottom>
					<h2 className="banner-title">Never heard of nodes before?</h2>
					<div className="beginners-container">
						<p className="banner-description">If you’re unsure of what a node is or how the farming process works, be sure to check out our intro for beginners</p>

						<Button
						color={"white"}
						size={"large"}
						content={"Get acquainted"}
						link={"https://medium.com/@nodefarms"}
						/>
					</div>
				</Fade>
				</section>
				<div className="wraper container">
					<Fade bottom>
					<img src={BenefitsSpiral} alt="benefits-spiral.png" className="decoration benefits-spiral" />
					<img src={Torus} alt="torus-laying.png" className="decoration torus-desktop" />
					</Fade>
					<Fade bottom>
					<SectionLayout
					align={"center"}
					title={"What can we offer you?"}
					container={"benefits-container"}>
						<div className="decoration ellipse4">
							<Ellipse />
						</div>
						<div className="decoration ellipse5">
							<Ellipse />
						</div>
						<img src={Torus} alt="torus-laying.png" className="decoration torus" />
						<Benefits 
						img={Wallet} 
						alt={"wallet-icon.png"} 
						title={"100% of every reward"} 
						text={"We don’t charge any fees from the rewards you get. You only pay for our services and the servers themselves"} />
						<Benefits 
						img={Docker} 
						alt={"docker-icon.png"} 
						title={"Automated node installation"} 
						text={"All nodes are set up instantly using Docker components."} />
						<Benefits 
						img={Favorite} 
						alt={"fav-icon.png"} 
						title={"Subscription"} 
						text={"Choose a node and pay for every day, cancellation and installation can be done at any time of the day"} />
						<Benefits 
						img={Metamask} 
						alt={"metamask-icon.png"} 
						title={"Metamask Integration"} 
						text={"Sign up using your desired wallet in a split second. Add funds to your account in two clicks"} />
						<Benefits 
						img={Grafana} 
						alt={"grafana-icon.png"} 
						title={"Grafana"} 
						text={"Statistics of every node, available in real-time. Only for premium users"} />
						<Benefits 
						img={Payment} 
						alt={"payment-icon.png"} 
						title={"Auto-payment"} 
						text={"Money is automatically withdrawn from your system account. No need to worry about paying in time"} />
					</SectionLayout>
					</Fade>
					<Fade bottom>
					<SectionLayout
					align={"center"}
					title={"Leading nodes, one click away"}
					description={"A list of hand-picked projects, available for you today"}
					container={"card-container"}>
						<div className="decoration ellipse6">
							<Ellipse />
						</div>
						<div className="decoration ellipse7">
							<Ellipse />
						</div>
						<Card
						img={Aleo}
						alt={"aleo-icon.png"}
						title={"Aleo"}
						text={"Aleo is a layer 1 blockchain bringing zero-knowledge technology to the web."}
						link={"https://www.aleo.org/"}/>
						<Card
						img={Taiko}
						alt={"taiko-icon.png"}
						title={"Taiko"}
						text={"Taiko is a decentralized, Ethereum-equivalent ZK-Rollup."} 
						link={"https://taiko.xyz/"}/>
						<Card
						img={Subspace}
						alt={"subspace-icon.jpeg"}
						title={"Subspace"}
						text={"Subspace is a new eco-friendly, scalable layer-one blockchain that is able to serve as an infrastructure layer for the entire Web3 ecosystem."} 
						link={"https://subspace.network/"}/>
						<Card
						img={Chainflip}
						alt={"chainflip-icon.png"}
						title={"Chainflip"}
						text={"Chainflip is a cross-chain AMM enabling native asset swaps without wrapped tokens or specialised wallets."} 
						link={"https://chainflip.io/"}/>
						<Card
						img={Lava}
						alt={"lava-icon.jpeg"}
						title={"Lava"}
						text={"Lava is a decentralized network of node runners, each incentivized to give you fast, reliable and accurate RPC."} 
						link={"https://www.lavanet.xyz/"}/>
						<Card
						img={Namada}
						alt={"namada-icon.png"}
						title={"Namada"}
						text={"Namada is a proof-of-stake L1 for interchain asset-agnostic privacy."} 
						link={"https://namada.net/"}/>
					</SectionLayout>
					</Fade>
				</div>
				<Fade bottom>
				<SectionLayout
				align={"center"}
				title={"What have you already missed"} >
					<img src={SliderRings} alt="slider-rings.png" className="decoration slider-rings" />
					<Slider>
						<Slide
						img={Aptos}
						alt={"aptos-icon.png"}
						title={"Aptos"}
						tokens={"300"}
						price={"10 - 20"}
						profit={"3 000 - 6 000"} />
						<Slide
						img={Solana}
						alt={"solana-icon.png"}
						title={"Solana"}
						tokens={"500"}
						price={"5 - 200"}
						profit={"1 500 - 60 000"} />
						<Slide
						img={Avalanche}
						alt={"avalance-icon.png"}
						title={"Avalanche"}
						tokens={"1 500"}
						price={"60 - 64"}
						profit={"90 000 - 96 000"} />
						<Slide
						img={Forta}
						alt={"forta-icon.png"}
						title={"Forta"}
						tokens={"3 000"}
						price={"0,6 - 1"}
						profit={"1 800 - 3 000"} />
						<Slide
						img={Aptos}
						alt={"aptos-icon.png"}
						title={"Aptos"}
						tokens={"300"}
						price={"10 - 20"}
						profit={"3 000 - 6 000"} />
						<Slide
						img={Solana}
						alt={"solana-icon.png"}
						title={"Solana"}
						tokens={"500"}
						price={"5 - 200"}
						profit={"1 500 - 60 000"} />
						<Slide
						img={Avalanche}
						alt={"avalance-icon.png"}
						title={"Avalanche"}
						tokens={"1 500"}
						price={"60 - 64"}
						profit={"90 000 - 96 000"} />
						<Slide
						img={Forta}
						alt={"forta-icon.png"}
						title={"Forta"}
						tokens={"3 000"}
						price={"0,6 - 1"}
						profit={"1 800 - 3 000"} />
					</Slider>
				</SectionLayout>
				</Fade>
				<div className="wraper container">
					<Fade bottom>
					<img src={SphereEllMobile} alt="sphere-ellipse-mobile.png" className="decoration sphere-ell-mobile" />
					<img src={SphereEllTablet} alt="sphere-ellipse-tablet.png" className="decoration sphere-ell-tablet" />
					<img src={SphereEllDesktop} alt="sphere-ellipse-desktop.png" className="decoration sphere-ell-desktop" />
					<img src={Sphere} alt="sphere.png" className="decoration sphere" />
					<div className="decoration eth2">
						<EthBubble />
					</div>
					<div className="decoration btc4">
						<BtcBubble />
					</div>
					<div className="decoration ellipse8">
						<Ellipse />
					</div>
					<div className="decoration ellipse9">
						<Ellipse />
					</div>
					<div className="decoration ellipse10">
						<Ellipse />
					</div>
					<div className="decoration ellipse11">
						<Ellipse />
					</div>
					<div className="c2a">
						<h2 className="banner-title">Don’t be the one to miss another opportunity</h2>
						<p className="banner-description">Do your research and become a node runner today</p>
						<Button
							color={"green"}
							size={"small"}
							content={"Launch App"}
							// link={'https://app.nodefarm.io/' + (query.get("ref") ?  `?ref=${query.get("ref")}` : '')}
							link={process.env.REACT_APP_LINK_TO_APP  + (query.get("ref") ?  `?ref=${query.get("ref")}` : '')}
						/>
					</div>
					</Fade>
				</div>
				<Footer />
			</div>
		</div>
	)
}