export { default as Spiral } from "./spiral.png"
export { ReactComponent as ArrowRight } from "./arrow-black.svg"
export { ReactComponent as LogoIcon } from "./Logo.svg"
export { ReactComponent as BtcBubble } from "./bitcoin-bubble.svg"
export { ReactComponent as EthBubble } from "./etherium-bubble.svg"
export { ReactComponent as Ellipse } from "./ellipse-transparent.svg"
export { default as Diamond } from "./diamond.png"
export { default as LinEllipse} from "./line-ellipse.png"
export { default as Torus } from "./torus-laying.png"
export { default as Sphere } from "./sphere-ring.png"
export { default as DiamondEllipse} from "./diamond-ellipse.png"
export { default as SliderRings} from "./slider-rings.png"
export { default as BenefitsSpiral } from "./benefits-spiral.png"
export { default as SphereEllDesktop } from "./sphere-ellipse-desktop.png"
export { default as SphereEllTablet } from "./sphere-ellipse-tablet.png"
export { default as SphereEllMobile } from "./sphere-ellipse-mobile.png"
export { default as Spiral404 } from "./spiral-404.png"